/*
 * @Author: szs
 * @Date: 2021-06-07 15:34:41
 * @LastEditTime: 2021-07-14 15:35:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \qianyue\src\components\company\test.js
 */
let Test = function (tokenClient, serviceAddr) {
  let TokenClient = tokenClient;
  let ServiceBaseAddress = serviceAddr;

  /**
   * 获取所有试题集
   * @param {any} success
   * @param {any} error
   */
  this.Questionnaire = function (success, error) {
    var url = ServiceBaseAddress + "/api/Questionnaire";
    tokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  /**
   * 获取试题集下的试题
   * @param {any} success
   * @param {any} error
   */
  this.getQuesList = function (questId, userSex, userAge, success, error) {
    var url = ServiceBaseAddress + `/api/Questionnaire/${questId}/${userSex}/${userAge}`;
    tokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  /**
   * 获取测试结果
   * @param {*} userId
   * @param {*} quesId
   * @param {*} success
   * @param {*} error
   */
  this.UserResultAll = function (userId, quesId, success, error) {
    var url = ServiceBaseAddress + `/api/Questionnaire/UserResultAll/${userId}/${quesId}`;
    tokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  /**
   * 添加答题人信息
   * @param {*} param
   * @param {*} success
   * @param {*} error
   */
  this.AddAnswerUser = function (param, success, error) {
    var url = ServiceBaseAddress + "/api/AnswerUserInfo";
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      param,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  /**
   * 修改答题人信息
   * @param {*} param
   * @param {*} success
   * @param {*} error
   */
  this.EditAnswerUser = function (param, success, error) {
    var url = ServiceBaseAddress + "/api/AnswerUserInfo";
    TokenClient.Put(
      url,
      true,
      { "Content-Type": "application/json" },
      param,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  /**
   * 获取结果ID
   * @param {*} param
   * @param {*} success
   * @param {*} error
   */
  this.getUserResultId = function (param, success, error) {
    var url = ServiceBaseAddress + "/api/Questionnaire/UserResult";
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      param,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  /**
   * 获取结果
   * @param {*} id
   * @param {*} success
   * @param {*} error
   */
  this.getUserResultById = function (id, success, error) {
    var url = ServiceBaseAddress + `/api/Questionnaire/UserResultDetail/${id}`;
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  this.getSelfDiagnosisHardware = function (keyword, pageIndex, diseaseName,resultName ,qId, item,success, error) {
    var url = ServiceBaseAddress + `/api/Questionnaire/FindUserQuestionnaireByHardware`;
    if (keyword) {
      url += `/${keyword}`;
    } else {
      url += "/%20";
    }
    if (pageIndex) {
      url += `/${pageIndex}`;
    }
    if (diseaseName) {
      url += `/${diseaseName}`;
    } else {
      url += "/%20";
    }
    if (resultName) {
      url += `/${resultName}`;
    } else {
      url += "/%20";
    }
    if (qId) {
      url += `/${qId}`;
    } 
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      item,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };

  /**
   * 获取测试记录
   * @param {*} id
   * @param {*} pageIndex
   * @param {*} success
   * @param {*} error
   */
  this.getUserResultByUser = function (id, keyword, pageIndex, diseaseName,resultName ,qId ,customerPhone ,success, error) {
    var url = ServiceBaseAddress + `/api/Questionnaire/UserResultByUser/${id}`;
    if (keyword) {
      url += `/${keyword}`;
    } else {
      url += "/%20";
    }
    if (pageIndex) {
      url += `/${pageIndex}`;
    }
    if (diseaseName) {
      url += `/${diseaseName}`;
    } else {
      url += "/%20";
    }
    if (resultName) {
      url += `/${resultName}`;
    } else {
      url += "/%20";
    }
    if (qId) {
      url += `/${qId}`;
    } 
    if (customerPhone) {
      url += `/${customerPhone}`;
    } 
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  /**
   * 获取结果
   * @param {*} id
   * @param {*} pageIndex
   * @param {*} success
   * @param {*} error
   */
  this.getCustomerResult = function (id, keyword,pageIndex, success, error) {
    var url = ServiceBaseAddress + `/api/Questionnaire/CustomerResult/${id}`;
    if (keyword) {
        url += `/${keyword}`;
      } else {
        url += "/%20";
      }
      if (pageIndex) {
        url += `/${pageIndex}`;
      }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  /**
   * 获取体质养生数据
   * @param {*} questionnaireResultId
   * @param {*} success
   * @param {*} error
   */
  this.getDetailById = function (questionnaireResultId, success, error) {
    var url = ServiceBaseAddress + `/api/Questionnaire/Info/Detail/${questionnaireResultId}`;
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  /**
   * 获取所有答题人
   * @param {any} success
   * @param {any} error
   */
  this.MyselfAnswerInfo = function (keyword,success, error) {
    var url = ServiceBaseAddress + `/api/AnswerUserInfo/${keyword}`;
    tokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  /**
   * 删除答题人
   * @param {any} id
   * @param {any} success
   * @param {any} error
   */
  this.RemoveAnswerUser = function (id, success, error) {
    var url = ServiceBaseAddress + "/api/AnswerUserInfo/" + id;
    TokenClient.Delete(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  this.addAnswerStaging = function (type,param, success, error) {
    var url = ServiceBaseAddress + `/api/AnswerStaging/${type}`;
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      param,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  this.putAnswerStaging = function (param, success, error) {
    var url = ServiceBaseAddress + "/api/AnswerStaging";
    TokenClient.Put(
      url,
      true,
      { "Content-Type": "application/json" },
      param,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  this.detailAnswerStaging = function (type, success, error) {
    var url = ServiceBaseAddress + `/api/AnswerStaging/${type}`;
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  this.getAnswerStaging = function (type, success, error) {
    var url = ServiceBaseAddress + `/api/AnswerStaging/${type}`;
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  this.deleteAnswerStaging = function (id, success, error) {
    var url = ServiceBaseAddress + "/api/AnswerStaging/" + id;
    TokenClient.Delete(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
};
export { Test };
